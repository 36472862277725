<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="설비 기본정보" :collapsed="true" class="cardClassDetailInfo">
        <template slot="card-button">
          <!-- <q-btn-group outline >
          </q-btn-group> -->
        </template>
        <template slot="card-detail">
          <div v-if="editable && !result.equipmentCd" class="col-12">
            <c-label-text title="설비를 선택하세요."></c-label-text>
          </div>
          <div v-if="editable && result.equipmentTypeCd" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="설비유형" :value="result.equipmentTypeName"></c-label-text>
          </div>
          <div v-if="editable && result.equipmentCd" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="관리번호" :value="result.equipmentCd"></c-label-text>
          </div>
          <div v-if="editable && result.equipmentName" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="설비명" :value="result.equipmentName"></c-label-text>
          </div>
          <div v-if="editable && result.recentInspectionDate" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="최근점검완료일" :value="result.recentInspectionDate"></c-label-text>
          </div>
          <div v-if="editable && result.inspectionCycleName" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="점검주기" :value="result.inspectionCycleName"></c-label-text>
          </div>
          <div v-if="editable && result.nextMonth" class="col-xs-6 col-sm-4 col-md-3 col-lg-3">
            <c-label-text title="차기법정예정월" :value="result.nextMonth"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-12">
      <q-form ref="editForm">
        <c-card class="cardClassDetailForm" title="설비 점검결과">
          <template slot="card-detail">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-field
                required
                :disabled="disabled"
                label="점검예정자"
                name="checkScheduleUserId"
                type="user" 
                v-model="result.checkScheduleUserId">
              </c-field>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                label="점검예정일"
                name="checkScheduleDate"
                v-model="result.checkScheduleDate">
              </c-datepicker>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-select
                label="점검유형"
                :disabled="disabled"
                required
                codeGroupCd="MDM_CHECK_KIND_CD"
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="equipmentCheckKindCd"
                v-model="result.equipmentCheckKindCd"
                @datachange="datachangeKind"
              ></c-select>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-dept
                label="점검부서"
                :required="true"
                :disabled="disabled"
                v-model="result.checkDeptCd"
              />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-field
                :required="true"
                :disabled="disabled"
                label="실제점검자"
                name="checkUserId"
                type="user" 
                v-model="result.checkUserId">
              </c-field>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-datepicker
                required
                :disabled="disabled"
                :editable="editable"
                label="실제점검일"
                name="checkDate"
                v-model="result.checkDate">
              </c-datepicker>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-plant
                :disabled="disabled"
                v-model="result.plantCd"
              />
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <c-select
                codeGroupCd="MIM_CHECK_RESULT_CD2"
                :editable="editable"
                :disabled="disabled"
                required
                type="edit"
                itemText="codeName"
                itemValue="code"
                name="checkResultCd"
                label="점검결과"
                v-model="result.checkResultCd"
              ></c-select>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <c-textarea
                :editable="editable"
                :disabled="disabled"
                label="점검결과요약"
                name="checkResultSummary"
                v-model="result.checkResultSummary">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </q-form>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
        align="left"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :result="result"
          />
        </template>
      </c-tab>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn :flat="true" v-if="editable && !updateMode && !disabled" label="설비선택" icon="add" @btnClicked="add"/>
          <c-btn :flat="true" v-if="editable && updateMode && !disabled" :showLoading="false" label="완료" icon="check" color="blue"  @btnClicked="resultComplete" />
          <c-btn
            :flat="true" 
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="result"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveInspection"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        noPlan: 'N',
        multiple: false,
        minEquipmentCheckId: '',
        findFlag: false,
        equipmentCd: '',
        plantCd: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      result: {
        minEquipmentCheckId: '',
        plantCd: '',
        plantName: '',
        checkDeptCd: '',
        checkDeptName: '',  
        checkUserId: '',
        checkUserName: '',
        sopMocId: '',
        equipmentCheckKindCd: null,
        equipmentCheckKindNm: '',
        checkStatusCd: '',
        checkScheduleUserName: '', 
        checkScheduleUserId: '',
        checkScheduleDate: '',
        equipmentTypeCd: '', 
        equipmentCd: '', 
        equipmentName: '',
        inspectionCycleName: '',
        inspectionCycleCd: '',
        recentInspectionDate: '',
        relatedLaws: '',
        regUserId: '',
        chgUserId: '',
        noPlanResultEnrollFlag: 'Y',
        checkResultCd: null,
        checkCompleteFlag: null,
        checkDate: '',

        equipmentResultList: [],
        equipmentImproveModels: []
      },
      tab: 'resultItemTab',
      tabItems: [
        { name: 'resultItemTab', icon: 'list', label: '설비별 점검항목', component: () => import(`${'./equipmentResultItemTab.vue'}`) },
        { name: 'imprTab', icon: 'construction', label: '개선관리', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`) },
      ],
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkRsltYnItems: [],

      saveUrl: 'transactionConfig.sop.min.equipment.result.insert.url',
      saveType: 'POST',
      isSave: false,
      insertUrl: '',
      updateUrl: '',
      getUrl: '',
      getEquipUrl: '',
      getResultItemUrl: '',
      title: '',
      updateMode: false,
    };
  },
  computed: {
    disabled() {
      return this.result.checkStatusCd === 'MCSC000015'
    },
    isOld() {
      return Boolean(this.popupParam.minEquipmentCheckId);
    },
    isMultiple() {
      return this.popupParam.selectData.length > 0;
    },
    imprTabInfo() {
      return {
        key: this.popupParam.minEquipmentCheckId, // 
        ibmTaskTypeCd: 'ITT0000055',
        requestContents: this.result.equipmentName,
        disabled: this.disabled,
        isOld: this.isOld,
        gridItem: {
          listUrl: selectConfig.sop.min.equipment.result.list.url,
          param: {
            minEquipmentCheckId: this.popupParam.minEquipmentCheckId,
            imprFlag: true,
          },
          title: '점검항목 별 개선사항 목록',
          merge: [
            { index: 0, colName: 'checkItemName' },
          ],
          columns: [
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목',
              align: 'center',
              colClass: 12,
              sortable: false,
              fix: true,
            },
            {
              name: 'checkWayName',
              field: 'checkWayName',
              label: '검사방법',
              colClass: 6,
              align: 'center',
              style: 'width:100px',
            },
            {
              name: 'judgmentResultTypeName',
              field: 'judgmentResultTypeName',
              label: '적합/부적합',
              colClass: 6,
              align: 'center',
              style: 'width:90px',
              sortable: false,
            },
            {
              name: 'checkResult',
              field: 'checkResult',
              label: '점검결과',
              colClass: 6,
              align: 'left',
              sortable: false,
              fix: true,
            },
            {
              name: 'decisionBasis',
              field: 'decisionBasis',
              label: '판정기준',
              colClass: 6,
              align: 'left',
              sortable: false,
            },
            {
              name: 'checkCaution',
              field: 'checkCaution',
              label: '점검시 안전사항',
              colClass: 12,
              align: 'left',
              sortable: false,
            },
          ],
          research: this.research,
        },
        gridOther: {
          title: '항목외 개선 목록',
        },
      }
    }
  },
  watch: {
    'research.item'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getEquipUrl = selectConfig.mdm.equipment.get.url;
      this.getUrl = selectConfig.sop.min.equipment.plan.get.url;
      this.getResultItemUrl = selectConfig.sop.min.equipment.result.item.get.url;
      this.insertUrl = transactionConfig.sop.min.equipment.result.insert.url;
      this.updateUrl = transactionConfig.sop.min.equipment.result.update.url;
      this.completeUrl = transactionConfig.sop.min.equipment.result.complete.url;
      // code setting
      this.chkRsltYnItems = [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ]
      // list setting
      this.title = this.popupParam.title;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.minEquipmentCheckId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.minEquipmentCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.result = _result.data;

          if (!this.result.equipmentImproveModels) {
            this.result.equipmentImproveModels = [];
          }
          this.updateMode = true;
        },);
      } else if (this.popupParam.findFlag) {
        this.$http.url = this.getEquipUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.popupParam.plantCd,
          equipmentCd: this.popupParam.equipmentCd,
        };
        this.$http.request((_result) => {
          if (_result.data) {
            this.result.minEquipmentCheckId= ''
            this.result.plantCd= this.popupParam.plantCd;
            this.result.plantName= _result.data.plantName;
            this.result.checkStatusCd= 'MCSC000005';
            this.result.equipmentTypeCd= _result.data.equipmentTypeCd; 
            this.result.equipmentTypeName= _result.data.equipmentTypeName; 
            this.result.equipmentCd= _result.data.equipmentCd; 
            this.result.equipmentName= _result.data.equipmentName;
            this.result.inspectionCycleName= _result.data.inspectionCycleName;
            this.result.inspectionCycleCd= _result.data.inspectionCycleCd;
            this.result.recentInspectionDate= _result.data.recentInspectionDate;
            this.result.relatedLaws= _result.data.relatedLaws;
            this.result.regUserId= this.$store.getters.user.userId;
            this.result.chgUserId= this.$store.getters.user.userId;
            this.result.relatedLawsName = _result.data.relatedLawsName;
            this.result.checkScheduleUserId= this.$store.getters.user.userId;
            this.result.checkScheduleDate= this.$comm.getToday();
            this.result.checkUserId= this.$store.getters.user.userId;
            this.result.checkDate= this.$comm.getToday();

            if (!this.result.equipmentCheckKindCd || !this.result.equipmentTypeCd) {
              this.result.equipmentResultList = [];
              return;
            }
            this.$http.url = this.$format(this.getResultItemUrl, this.result.equipmentTypeCd, this.result.equipmentCheckKindCd);
            this.$http.type = 'GET';
            this.$http.request((_result) => {
              this.result.equipmentResultList = this.$_.clone(_result.data);

              this.$_.forEach(this.result.equipmentResultList, _item => {
                _item.editFlag = 'C'
              })
            },);
          }
        },);
      }
    },
    rowClick(row) {
      this.result = this.$_.clone(row);
    },
    add() {
      // 초기에는 userId에 tempId를 부여
      this.popupOptions.title = '설비 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.result.minEquipmentCheckId= ''
        this.result.plantCd= data[0].plantCd;
        this.result.plantName= data[0].plantName;
        this.result.checkStatusCd= 'MCSC000005';
        this.result.checkScheduleUserName= ''; 
        this.result.equipmentTypeCd= data[0].equipmentTypeCd; 
        this.result.equipmentTypeName= data[0].equipmentTypeName; 
        this.result.equipmentCd= data[0].equipmentCd; 
        this.result.equipmentName= data[0].equipmentName;
        this.result.inspectionCycleName= data[0].inspectionCycleName;
        this.result.inspectionCycleCd= data[0].inspectionCycleCd;
        this.result.recentInspectionDate= data[0].recentInspectionDate;
        this.result.relatedLaws= data[0].relatedLaws;
        this.result.regUserId= this.$store.getters.user.userId;
        this.result.chgUserId= this.$store.getters.user.userId;
        this.result.relatedLawsName = data[0].relatedLawsName;
        this.result.checkScheduleUserId= this.$store.getters.user.userId;
        this.result.checkScheduleDate= this.$comm.getToday();
        this.result.checkUserId= this.$store.getters.user.userId;
        this.result.checkDate= this.$comm.getToday();

        if (!this.result.equipmentCheckKindCd || !this.result.equipmentTypeCd) {
          this.result.equipmentResultList = [];
          return;
        }
        this.$http.url = this.$format(this.getResultItemUrl, this.result.equipmentTypeCd, this.result.equipmentCheckKindCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.result.equipmentResultList = this.$_.clone(_result.data);

          this.$_.forEach(this.result.equipmentResultList, _item => {
            _item.editFlag = 'C'
          })
        },);
      }
    },
    saveInspection() {
      if (this.popupParam.minEquipmentCheckId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
        // this.result.checkScheduleDate = this.result.checkDate
        // this.result.checkScheduleUserId = this.result.checkUserId
      }
      if (this.popupParam.noPlan === 'Y') {
        this.result.noPlanResultEnrollFlag = 'Y';
      }
      if (!this.result.equipmentCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비를 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      } 
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.minEquipmentCheckId = result.data
      this.getDetail();
    },
    resultComplete() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '점검완료 하시겠습니까?',
            // TODO : 점검완료 하시겠습니까?
            type: 'warning', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.result.checkCompleteFlag = 'Y';
              this.$http.url = this.completeUrl;
              this.$http.type = 'PUT';
              this.$http.param = this.result;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getDetail();
              },);
            },
            // 취소 callback 함수
            cancelCallback: () => {
          },
        });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
        
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    datachangeKind() {
      if (!this.result.equipmentCheckKindCd || !this.result.equipmentTypeCd) {
        this.result.equipmentResultList = [];
        return;
      }
      this.$http.url = this.$format(this.getResultItemUrl, this.result.equipmentTypeCd, this.result.equipmentCheckKindCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.result.equipmentResultList = this.$_.clone(_result.data);

        this.$_.forEach(this.result.equipmentResultList, _item => {
          _item.editFlag = 'C'
        })
        console.log('this.result.equipmentResultList', this.result.equipmentResultList)
      },);
    },
  }
};
</script>
