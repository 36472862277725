var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailInfo",
              attrs: { title: "설비 기본정보", collapsed: true },
            },
            [
              _c("template", { slot: "card-button" }),
              _c("template", { slot: "card-detail" }, [
                _vm.editable && !_vm.result.equipmentCd
                  ? _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("c-label-text", {
                          attrs: { title: "설비를 선택하세요." },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.result.equipmentTypeCd
                  ? _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-4 col-md-3 col-lg-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "설비유형",
                            value: _vm.result.equipmentTypeName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.result.equipmentCd
                  ? _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-4 col-md-3 col-lg-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "관리번호",
                            value: _vm.result.equipmentCd,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.result.equipmentName
                  ? _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-4 col-md-3 col-lg-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "설비명",
                            value: _vm.result.equipmentName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.result.recentInspectionDate
                  ? _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-4 col-md-3 col-lg-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "최근점검완료일",
                            value: _vm.result.recentInspectionDate,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.result.inspectionCycleName
                  ? _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-4 col-md-3 col-lg-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "점검주기",
                            value: _vm.result.inspectionCycleName,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.editable && _vm.result.nextMonth
                  ? _c(
                      "div",
                      { staticClass: "col-xs-6 col-sm-4 col-md-3 col-lg-3" },
                      [
                        _c("c-label-text", {
                          attrs: {
                            title: "차기법정예정월",
                            value: _vm.result.nextMonth,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "설비 점검결과" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            label: "점검예정자",
                            name: "checkScheduleUserId",
                            type: "user",
                          },
                          model: {
                            value: _vm.result.checkScheduleUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkScheduleUserId", $$v)
                            },
                            expression: "result.checkScheduleUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "점검예정일",
                            name: "checkScheduleDate",
                          },
                          model: {
                            value: _vm.result.checkScheduleDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkScheduleDate", $$v)
                            },
                            expression: "result.checkScheduleDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            label: "점검유형",
                            disabled: _vm.disabled,
                            required: "",
                            codeGroupCd: "MDM_CHECK_KIND_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "equipmentCheckKindCd",
                          },
                          on: { datachange: _vm.datachangeKind },
                          model: {
                            value: _vm.result.equipmentCheckKindCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "equipmentCheckKindCd", $$v)
                            },
                            expression: "result.equipmentCheckKindCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-dept", {
                          attrs: {
                            label: "점검부서",
                            required: true,
                            disabled: _vm.disabled,
                          },
                          model: {
                            value: _vm.result.checkDeptCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkDeptCd", $$v)
                            },
                            expression: "result.checkDeptCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-field", {
                          attrs: {
                            required: true,
                            disabled: _vm.disabled,
                            label: "실제점검자",
                            name: "checkUserId",
                            type: "user",
                          },
                          model: {
                            value: _vm.result.checkUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkUserId", $$v)
                            },
                            expression: "result.checkUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "실제점검일",
                            name: "checkDate",
                          },
                          model: {
                            value: _vm.result.checkDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkDate", $$v)
                            },
                            expression: "result.checkDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-plant", {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.result.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "plantCd", $$v)
                            },
                            expression: "result.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6",
                      },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "MIM_CHECK_RESULT_CD2",
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            required: "",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "checkResultCd",
                            label: "점검결과",
                          },
                          model: {
                            value: _vm.result.checkResultCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkResultCd", $$v)
                            },
                            expression: "result.checkResultCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                      },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "점검결과요약",
                            name: "checkResultSummary",
                          },
                          model: {
                            value: _vm.result.checkResultSummary,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "checkResultSummary", $$v)
                            },
                            expression: "result.checkResultSummary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c("c-tab", {
            attrs: {
              type: "tabcard",
              tabItems: _vm.tabItems,
              inlineLabel: true,
              align: "left",
            },
            on: {
              "update:tabItems": function ($event) {
                _vm.tabItems = $event
              },
              "update:tab-items": function ($event) {
                _vm.tabItems = $event
              },
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (tab) {
                  return [
                    _c(tab.component, {
                      tag: "component",
                      attrs: { result: _vm.result },
                    }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.tab,
              callback: function ($$v) {
                _vm.tab = $$v
              },
              expression: "tab",
            },
          }),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _vm.editable && !_vm.updateMode && !_vm.disabled
                  ? _c("c-btn", {
                      attrs: { flat: true, label: "설비선택", icon: "add" },
                      on: { btnClicked: _vm.add },
                    })
                  : _vm._e(),
                _vm.editable && _vm.updateMode && !_vm.disabled
                  ? _c("c-btn", {
                      attrs: {
                        flat: true,
                        showLoading: false,
                        label: "완료",
                        icon: "check",
                        color: "blue",
                      },
                      on: { btnClicked: _vm.resultComplete },
                    })
                  : _vm._e(),
                _vm.editable && !_vm.disabled
                  ? _c("c-btn", {
                      attrs: {
                        flat: true,
                        url: _vm.saveUrl,
                        isSubmit: _vm.isSave,
                        param: _vm.result,
                        mappingType: _vm.saveType,
                        label: "저장",
                        icon: "save",
                      },
                      on: {
                        beforeAction: _vm.saveInspection,
                        btnCallback: _vm.saveCallback,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }